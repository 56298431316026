<template>
<!--  <div alt="bg" id="bg"></div>-->
  <nav>
<!--    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>-->
    <span class="band"></span>
    <h1>LE&nbsp;QUIZ<br />DU LS</h1>
    <span class="band"></span>
  </nav>
  <router-view/>
<!--  <div id="transi"><img :src="require('@/assets/LS logo light corrected white.svg')" /></div>-->
</template>

<style>
@font-face {
  font-family: bluuNext;
  font-display: auto;
  unicode-range: U+000-3FF;
  src: local('bluuNext'), url('assets/BluuNext-Bold.otf');
}
@font-face {
  font-family: futura;
  font-display: auto;
  unicode-range: U+000-3FF;
  src: local('futura'), url('assets/futura light bt.ttf');
}
html {
  height: 100%;
  position: fixed;
  font-size: min(2vw, 3vh);
  max-width: 100vw;
}
body {
  background: #12326D;
  /*background: conic-gradient(#12326D 0 5%,#293d7a 0% 15%, #12326D 0 25%,#293d7a 0 35%, #12326D 0 45%,#293d7a 0 55%,#12326D 0 65%,#293d7a 0 75%, #12326D 0 85%,#293d7a 0 95%, #12326D 0 100%) fixed center / cover;*/
  background: repeating-conic-gradient(#12326d -5% 5%, #293d7a 5% 15%) fixed center/cover;
/*  can add   radial-gradient(transparent 50%, #12326D 70%) fixed center/cover,   at the beginning */
  margin: 0;
  height: 100%;
}

#app {
  font-family: futura, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /*color: #2c3e50;*/
  color: white;
  font-weight: 1000;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: space-between;
  justify-content: space-between;
}

nav {
  display: flex;
  margin: 1vh 0;
  max-height: 15vh;
  max-width: 100%;
}
.band {
  /*padding: 30px;*/
  /*noinspection CssInvalidFunction*/
  background: linear-gradient(to top, transparent 35%, green 0 45%, white 45% 55%, transparent 0);
  width: 40vw;
  /*height: auto;*/
}

nav a {
  /*font-weight: bold;*/
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

#main {
  /*flex-grow: 5;*/
  padding-bottom: 2vh;
}

nav > h1, #footer {
  font-family: bluuNext, futura, Avenir, Helvetica, Arial, sans-serif;
}
h1 {
  /*background: #12326D;*/
  min-width: 20vw;
  margin: auto;
  /*noinspection CssInvalidFunction*/
  font-size: min(5vw, 3rem, 6vh);
  /*flex-grow: 1;*/
  padding: 0 3vw;
}
h2 {
  font-size: 2rem;
}

button, td {
  padding: 1vh 1vw;
  /*margin: 2vw;*/
  border: 2px solid white;
  border-radius: 10px;
  background: rgba(255,255,255,.2);
  color: white; /*#12326D;*/
  font-weight: bold;
  font-size: 1.1rem;
}
img {
  max-width: 75%;
  max-height: 90%;
}
footer > img {
  max-height: 5vh;
}
table {
  margin: auto;
  border-collapse: separate;
  border-spacing: 5vw 5vh;
}
.main td {
  height: 10vh;
  width: 30vw;
}

@media (max-width: 1000px) {
  html {
    font-size: min(3vw, 3.2vh);
  }
  .main td {
    height: 10vh;
    width: 45vw;
}
}
@media (min-width: 1500px) {
  html {
    font-size: min(1.5vw, 4vh);
  }
}

</style>
