import { createRouter, createWebHistory /*createWebHashHistory*/ } from 'vue-router' // check https://router.vuejs.org/guide/essentials/history-mode.html
// import HomeView from '../views/HomeView.vue'
// import StateHandler from "@/views/StateHandler";
// import AdminView from "@/views/AdminView";
// import ShowView from "@/views/ShowView";

const routes = [
  {
    path: '/',
    name: 'main', // 'home',
    component: () => import( '../views/StateHandler') // StateHandler) // HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/admin',
    name: 'adminPanel',
    component: () => import('../views/AdminView') //AdminView)
  },
  {
    path: '/proj',
    name: 'overview', // 'home',
    component: () => import('../views/ShowView') //ShowView) // HomeView
  },
  { path: '/:pathMatch(.*)', redirect: '/' }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
